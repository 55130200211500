import { Injectable } from '@angular/core';
import { Base64File } from './models';

@Injectable()
export class FileService {

	convertFile(file: File): Promise<Base64File> {
		return new Promise<Base64File>((resolve, reject) => {
			const reader = new FileReader();

			reader.onabort = () => { reject('onabort'); };
			reader.onerror = () => { reject('onerror'); };
			reader.onloadend = (onLoadEndEvent) => {
				resolve({
					filename: file.name,
					dataType: reader.result.toString().split(',')[0].split(':')[1].split(';')[0],
					data: reader.result.toString().split(',')[1],
					size: file.size
				});
			};

			reader.readAsDataURL(file);
		});
	}
}
