<header>
	<!-- HARDCODED -->
	<h1>Bezwaar tegen afwijzing vergunning</h1>
</header>
<section class="main-content" *ngIf="portalInstance">
	<div class="row">
		<div class="col-md-12">
			<app-portal-case-information [caseId]="portalInstance.caseId"></app-portal-case-information>
		</div>
	</div>
</section>
