import { Logger } from '../logger';
const logger = new Logger('DialogService');

import { Injectable, Output, EventEmitter } from '@angular/core';
import { OkCancelModalOptions } from '../shared/ok-cancel-modal/ok-cancel-modal.component';

@Injectable()
export class DialogService {

	@Output() okCancelDialogAdded: EventEmitter<OkCancelModalOptions> = new EventEmitter();

	createOkCancelDialog(okButtonText: string, cancelButtonText: string, content: string, htmlContent: boolean = false): Promise<void> {
		return new Promise((resolve, reject) => {
			let modalData: OkCancelModalOptions;
			modalData = {
				okButtonText: okButtonText,
				cancelButtonText: cancelButtonText,
				content: content,
				htmlContent: htmlContent,
				resolve: resolve,
				reject: reject
			};

			this.delayedEmit(this.okCancelDialogAdded, modalData);
		});
	}

	// Set can be called during Angular lifecycle, which limits change detections. Timeout pushes it to next Javascript cycle
	private delayedEmit<T>(emitter: EventEmitter<T>, value: T) {
		setTimeout(() => {
			emitter.emit(value);
		}, 0);
	}
}
