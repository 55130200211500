import { Logger } from '../../logger';
const logger = new Logger('PublicationComponent');

import { Component, OnInit } from '@angular/core';
import { ShellService } from '../shell.service';
import { ActivatedRoute, Router } from '@angular/router';

import { PortalInstance, PublicationDto, FileSummaryDto } from '../../shared/models';
import { PortalService } from '../portal.service';

@Component({
	templateUrl: './publication.component.html'
})
export class PublicationComponent implements OnInit {
	parent: PortalInstance;
	caseId: string;
	publication: PublicationDto;
	files: FileSummaryDto[];

	constructor(
		private router: Router,
		private shellService: ShellService,
		private portalService: PortalService,
		private route: ActivatedRoute) { }

	ngOnInit() {
		this.route.data.subscribe((data: { portalInstance: PortalInstance, publication: PublicationDto }) => {
			logger.trace(`Route data changed. PortalInstance: ${data.portalInstance}, Publication: ${data.publication}`);

			this.publication = data.publication;
			this.files = data.publication.files;
			this.parent = data.portalInstance;
			this.caseId = data.portalInstance.caseId;
			this.shellService.setPortalInstance(data.portalInstance);
		});
	}

	downloadFile(file: FileSummaryDto): void {
		this.portalService.downloadPublicationFile(this.parent.id, this.publication.id, file.fileId);
	}
}
