import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { filter, switchMap, tap } from 'rxjs/operators';

import { AuthService } from './auth.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class AuthAutoLoginGuard implements CanActivate {


  constructor(
    private authService: AuthService,
  ) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> {
	if (environment.useAuthentication) {
		return this.authService.isDoneLoading$.pipe(
		filter(isDone => isDone),
		switchMap(_ => this.authService.isAuthenticated$),
		tap(isAuthenticated => isAuthenticated || this.authService.login(state.url)),
		);
	} else {
		return of(true)
	}
  }
}
