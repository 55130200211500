<div class="modal fade" tabindex="-1" role="dialog">
	<div class="modal-dialog" role="document">
		<div class="modal-content">
			<div class="modal-header">
				<button type="button" class="close" aria-label="Close" (click)="closeClick()">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body">
				<div [innerHtml]="options.htmlContent" *ngIf="options.htmlContent"></div>
				<p *ngIf="!options.htmlContent">
					{{ options.content }}
				</p>
				<button type="button" class="btn btn-primary" (click)="okClick()">{{ options.okButtonText }}</button>
				<button type="button" class="btn btn-default" (click)="cancelClick()">{{ options.cancelButtonText }}</button>
			</div>
		</div>
	</div>
</div>
