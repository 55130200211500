export enum LogLevel {
	trace = 0,
	debug = 1,
	log = 2,
	info = 3,
	warn = 4,
	error = 5,
	fatal = 6,
	none = 999
}

let currentLogLevel: LogLevel = LogLevel.trace;

export function SetLogLevel(logLevel: LogLevel) {
	currentLogLevel = logLevel;
}

export class Logger {
	public name: string;

	constructor(name: string) {
		this.name = name;
	}

	public trace(message: any) {
		this.log(LogLevel.trace, message);
	}

	public debug(message: any) {
		this.log(LogLevel.debug, message);
	}

	public info(message: any) {
		this.log(LogLevel.info, message);
	}

	public warn(message: any) {
		this.log(LogLevel.warn, message);
	}

	public error(message: any) {
		this.log(LogLevel.error, message);
	}

	public fatal(message: any) {
		this.log(LogLevel.fatal, message);
	}

	private log(logLevel: LogLevel, message: any) {
		if (logLevel < currentLogLevel) {
			return;
		}

		const output = this.getOutput(logLevel);
		const messageWithLoggerName = `${this.name} |||| ${message}`;
		switch (+logLevel) {
			case LogLevel.trace:
				output(`TRACE: ${messageWithLoggerName}`);
				break;
			case LogLevel.debug:
				output(`DEBUG: ${messageWithLoggerName}`);
				break;
			case LogLevel.info:
				output(` INFO: ${messageWithLoggerName}`);
				break;
			case LogLevel.warn:
				output(` WARN: ${messageWithLoggerName}`);
				break;
			case LogLevel.error:
				output(`ERROR: ${messageWithLoggerName}`);
				break;
			case LogLevel.fatal:
				output(`FATAL: ${messageWithLoggerName}`);
				break;
			case LogLevel.none:
			default:
				return;
		}
	}

	private getOutput(logLevel: LogLevel): (message: any) => void {
		return this.getConsoleOutput(logLevel);
	}

	private getConsoleOutput(logLevel: LogLevel): (message: any) => void {
		switch (+logLevel) {
			case LogLevel.trace:
			case LogLevel.debug:
				return (message: any) => console.log(message);
			case LogLevel.info:
				return (message: any) => console.info(message);
			case LogLevel.warn:
				return (message: any) => console.warn(message);
			case LogLevel.error:
			case LogLevel.fatal:
				return (message: any) => console.error(message);
			case LogLevel.none:
			default:
				return console.log;
		}
	}
}
