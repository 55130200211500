import { SetLogLevel, LogLevel, Logger } from '../app/logger';
const logger = new Logger('Environment');

/*
	BL: This file is no longer the Angular environment file you expect,
	but it's function is the same so it's kept at the same location.

	Do not use the build / serve arguments. Settings are defined in settings.json, which is not
	compiled and it's values are changed through variable substitution in release builds
*/
export const environment = {
	name: '...',
	production: false,
	useAuthentication: false,
	openidIssuer : '',
	openidClientId : '',
	apiBaseUrl: '...',
	contactPhoneNumber: '...'
};

(function init() {
	let settings = loadSettings();
	environment.name = settings.environment;
	environment.production = settings.production;
	environment.useAuthentication = settings.useAuthentication;
	environment.openidIssuer = settings.openidIssuer;
	environment.openidClientId = settings.openidClientId;
	environment.apiBaseUrl = settings.apiBaseUrl;
	environment.contactPhoneNumber = settings.contactPhoneNumber;

	if (environment.production) {
		SetLogLevel(LogLevel.warn);
	} else {
		SetLogLevel(LogLevel.debug);
	}

	logger.info('Environment initialized');
})();

function loadSettings() {
	let json = getJsonFile('/settings.json');
	return JSON.parse(json);
}

function getJsonFile(url: string) {
	let xmlhttp = new XMLHttpRequest();
	if (xmlhttp.overrideMimeType) {
		xmlhttp.overrideMimeType('application/json');
	}
	xmlhttp.open('GET', url, false);
	xmlhttp.send();
	if (xmlhttp.status === 200) {
		return xmlhttp.responseText;
	} else {
		throw xmlhttp.responseText;
	}
}
