import { VERSION, Component, OnInit } from '@angular/core';
import { ApiInformationService } from './api-information.service';
import { PackageInformationService } from './package-information.service';

import { environment } from '../../environments/environment';

@Component({
	selector: 'app-app-information',
	templateUrl: './app-information.component.html'
})
export class AppInformationComponent implements OnInit {
	public angularVersion: string;
	public octopusPortaalFrontEndVersion: string;
	public octopusPortaalFrontEndConfiguration: string;
	public octopusPortaalApiVersion: string;

	constructor(private apiInformationService: ApiInformationService, private packageInformationService: PackageInformationService) { }

	ngOnInit() {
		this.angularVersion = VERSION.full;
		this.octopusPortaalFrontEndVersion = this.packageInformationService.getVersion();
		this.octopusPortaalFrontEndConfiguration = environment.name;
		this.apiInformationService.getVersion().subscribe(version => this.octopusPortaalApiVersion = version);
	}
}
