import { Logger } from '../logger';
const logger = new Logger('PublicationResolver');

import { Injectable } from '@angular/core';

import { Observable ,  EMPTY} from 'rxjs';
import { Resolve, RouterStateSnapshot, ActivatedRouteSnapshot, Router } from '@angular/router';

import { catchError } from 'rxjs/operators';

import { PublicationDto } from '../shared/models';
import { PortalService } from './portal.service';

@Injectable()
export class PublicationResolver implements Resolve<PublicationDto> {
	constructor(private portalService: PortalService, private router: Router) { }

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<PublicationDto> {
		const portalId = route.paramMap.get('portalId');
		const publicationId = route.paramMap.get('publicationId');
		logger.trace(`Resolve for publication: ${publicationId} (instance: ${portalId})`);

		if (!portalId || !publicationId) {
			return;
		}

		return this.portalService.getPublication(portalId, publicationId).pipe(
			catchError(err => {
				if (err.status === 404) {
					this.router.navigate(['notfound'], { skipLocationChange: true });
					return EMPTY;
				}

				if (err.status === 410) {
					this.router.navigate(['expired'], { skipLocationChange: true });
					return EMPTY;
				}

				throw err;
			}));
	}
}
