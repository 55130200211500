import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { PortalRoutingModule } from './portal-routing.module';
import { SharedModule } from '../shared/shared.module';

import { CaseService } from './case.service';
import { PortalService } from './portal.service';
import { ShellService } from './shell.service';
import { DialogService } from './dialog.service';

import { PortalShellComponent } from './shell.component';
import { PortalInstanceComponent } from './portal-instance/portal-instance.component';
import { InformationRequestComponent } from './information-request/information-request.component';
import { PortalItemHeaderComponent } from './portal-item-header/portal-item-header.component';
import { CaseInformationComponent } from './case-information/case-information.component';
import { PortalItemRemarksComponent } from './portal-item-remarks/portal-item-remarks.component';
import { ShellMenuComponent } from './shell-menu/shell-menu.component';
import { InformationRequestFilesComponent } from './information-request-files/information-request-files.component';
import { PublicationComponent } from './publication/publication.component';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		PortalRoutingModule,
		SharedModule
	],
	declarations: [
		PortalShellComponent,
		PortalInstanceComponent,
		InformationRequestComponent,
		PortalItemHeaderComponent,
		CaseInformationComponent,
		PortalItemRemarksComponent,
		ShellMenuComponent,
		InformationRequestFilesComponent,
		PublicationComponent
	],
	providers: [
		CaseService,
		PortalService,
		ShellService,
		DialogService
	]
})
export class PortalModule {

}
