import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { PortalShellComponent } from './shell.component';
import { PortalInstanceComponent } from './portal-instance/portal-instance.component';
import { InformationRequestComponent } from './information-request/information-request.component';
import { PublicationComponent } from './publication/publication.component';

import { PortalInstanceResolver } from './portal-instance.resolver';
import { InformationRequestResolver } from './information-request.resolver';
import { PublicationResolver } from './publication.resolver';
import { AuthAutoLoginGuard } from '../auth/auth-auto-login.guard';

const portalRoutes: Routes = [
	{
		path: 'portal',
		component: PortalShellComponent,
		children: [
			{
				path: ':portalId',
				component: PortalInstanceComponent,
				canActivate: [AuthAutoLoginGuard],
				resolve: {
					portalInstance: PortalInstanceResolver
				}
			},
			{
				path: ':portalId/informationrequest/:informationRequestId',
				component: InformationRequestComponent,
				canActivate: [AuthAutoLoginGuard],
				resolve: {
					portalInstance: PortalInstanceResolver,
					informationRequest: InformationRequestResolver
				}
			},
			{
				path: ':portalId/publication/:publicationId',
				component: PublicationComponent,
				canActivate: [AuthAutoLoginGuard],
				resolve: {
					portalInstance: PortalInstanceResolver,
					publication: PublicationResolver
				}
			}
		]
	}
];

@NgModule({
	imports: [
		RouterModule.forChild(portalRoutes)
	],
	exports: [
		RouterModule
	],
	providers: [
		PortalInstanceResolver,
		InformationRequestResolver,
		PublicationResolver
	]
})
export class PortalRoutingModule { }
