import { Logger } from '../../logger';
const logger = new Logger('PortalItemHeaderComponent');

import { Component, Input } from '@angular/core';

import { PortalItemState, IPortalItem } from '../../shared/models';

@Component({
	selector: 'app-portal-item-header',
	templateUrl: './portal-item-header.component.html'
})
export class PortalItemHeaderComponent {
	public portalItemStates = PortalItemState;

	@Input() portalItem: IPortalItem;
	@Input() deadline?: Date;
}
