import { Logger } from '../logger';
const logger = new Logger('PortalService');

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { environment } from '../../environments/environment';

import { PortalInstance, InformationRequestDto, PublicationDto } from '../shared/models';
import {
	AddFileToInformationRequestCommand,
	DeleteFileFromInformationRequestCommand,
	UpdateCustomTextOfInformationRequestCommand,
	SubmitInformationRequestCommand,
	AddAdditionalInformationRequestCommand
} from '../shared/commands';

const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

@Injectable()
export class PortalService {
	private portalUrl = `${environment.apiBaseUrl}/api/portal`;

	constructor(private http: HttpClient) { }

	getAllowedFileExtensions(): Observable<string[]> {
		const apiPortalUrl = `${this.portalUrl}/allowedfileextensions`;
		logger.trace(`Requesting allowed file extensions from '${apiPortalUrl}'`);
		return this.http.get<string[]>(apiPortalUrl, httpOptions).pipe(
			catchError(this.createGetErrorHandler<string[]>(`getAllowedFileExtensions()`)));
	}

	getPortalInstance(portalId: string): Observable<PortalInstance> {
		const apiPortalUrl = `${this.portalUrl}/${portalId}`;
		logger.trace(`Requesting Portal for '${portalId}' from '${apiPortalUrl}'`);
		return this.http.get<PortalInstance>(apiPortalUrl, httpOptions).pipe(
			catchError(this.createGetErrorHandler<PortalInstance>(`getPortalInstance(${portalId})`)));
	}

	getInformationRequest(portalId: string, informationRequestId: string): Observable<InformationRequestDto> {
		const apiInformationRequestUrl = `${this.portalUrl}/${portalId}/informationrequests/${informationRequestId}`;
		logger.trace(`Requesting Information Request for '${informationRequestId}' from '${apiInformationRequestUrl}'`);
		return this.http.get<InformationRequestDto>(apiInformationRequestUrl, httpOptions).pipe(
			catchError(this.createGetErrorHandler<InformationRequestDto>(`getInformationRequest(${informationRequestId})`)));
	}

	getPublication(portalId: string, publicationId: string): Observable<PublicationDto> {
		const apiPublicationUrl = `${this.portalUrl}/${portalId}/publications/${publicationId}`;
		logger.trace(`Requesting Publication for '${publicationId}' from '${apiPublicationUrl}'`);
		return this.http.get<PublicationDto>(apiPublicationUrl, httpOptions).pipe(
			catchError(this.createGetErrorHandler<PublicationDto>(`getPublication(${publicationId})`)));
	}

	private createGetErrorHandler<T>(callingOperation = 'operation') {
		return (error: any): Observable<T> => {
			if (error.status === 410 || error.status === 404) {
				logger.warn(`${callingOperation} failed: ${error.message}`);
			} else {
				logger.error(`${callingOperation} failed: ${error.message}`);
			}
			throw error;
		};
	}

	addFileToInformationRequest(command: AddFileToInformationRequestCommand, file: File): Promise<any> {
		const apiAddFileUrl = `${this.portalUrl}/${command.portalInstanceId}/informationrequests/${command.informationRequestId}/addfile`;
		logger.info(`Adding file to Information Request '${command.informationRequestId}' - '${apiAddFileUrl}'`);

		let formData: FormData = new FormData();
		formData.append('uploadFile', file, file.name);

		return this.http.post<any>(apiAddFileUrl, formData)
			.toPromise<any>()
			.catch(this.createPromiseErrorHandler(`addFileToInformationRequest(command)`));
	}

	deleteFileFromInformationRequest(command: DeleteFileFromInformationRequestCommand): Promise<void> {
		const apiDeleteFileUrl = `${this.portalUrl}/${command.portalInstanceId}/informationrequests/${command.informationRequestId}/deletefile`;
		logger.info(`Deleting file '${command.fileId}' from Information Request '${command.informationRequestId}' - '${apiDeleteFileUrl}'`);
		return this.http.post<any>(apiDeleteFileUrl, command, httpOptions)
			.toPromise<void>()
			.catch(this.createPromiseErrorHandler(`deleteFileFromInformationRequest(command)`));
	}

	updateCustomTextOfInformationRequest(command: UpdateCustomTextOfInformationRequestCommand): Promise<void> {
		const apiUpdateTextUrl = `${this.portalUrl}/${command.portalInstanceId}/informationrequests/${command.informationRequestId}/updatecustomtext`;
		logger.info(`Updating custom text of Information Request '${command.informationRequestId}' - '${apiUpdateTextUrl}'`);
		return this.http.post<void>(apiUpdateTextUrl, command, httpOptions)
			.toPromise<void>()
			.catch(this.createPromiseErrorHandler(`updateCustomTextOfInformationRequest(command)`));
	}

	submitInformationRequest(command: SubmitInformationRequestCommand): Promise<void> {
		const apiSubmitUrl = `${this.portalUrl}/${command.portalInstanceId}/informationrequests/${command.informationRequestId}/submit`;
		logger.info(`Submitting information request '${command.informationRequestId}' - '${apiSubmitUrl}'`);
		return this.http.post<any>(apiSubmitUrl, command, httpOptions)
			.toPromise<void>()
			.catch(this.createPromiseErrorHandler(`submitInformationRequest(command)`));
	}

	// downloadInformationRequestFile(portalInstanceId: any, informationRequestId: string, fileId: string) {
	// 	const downloadUrl = `${this.portalUrl}/${portalInstanceId}/informationrequests/${informationRequestId}/files/${fileId}/download`;
	// 	// assume a correctly implemented api service with content disposition
	// 	window.open(downloadUrl);
	// }

	downloadPublicationFile(portalInstanceId: any, publicationId: string, fileId: string) {
		const downloadUrl = `${this.portalUrl}/${portalInstanceId}/publications/${publicationId}/files/${fileId}/download`;
		// assume a correctly implemented api service with content disposition
		this.downloadFile(downloadUrl, function(blob) {
            var url = URL.createObjectURL(blob);
            window.open(url);
        });
	}

	downloadFile(url, success) {
        var xhr = new XMLHttpRequest();
        xhr.open('GET', url, true);
		if (localStorage.getItem("access_token") != null) {
			xhr.setRequestHeader("Authorization", "Bearer " + localStorage.getItem("access_token"));
		}
        xhr.responseType = "blob";
        xhr.onreadystatechange = function() {
            if (xhr.readyState == 4) {
                if (success) success(xhr.response);
            }
        };
        xhr.send(null);
    }

	addAdditionalInformationRequest(command: AddAdditionalInformationRequestCommand): Promise<any> {
		const addAdditionalInformationRequestUrl = `${this.portalUrl}/${command.portalInstanceId}/informationrequests/addadditional`;
		logger.info(`Adding additional to Information Request to '${command.portalInstanceId}'`);
		return this.http.post<any>(addAdditionalInformationRequestUrl, command, httpOptions)
			.toPromise<any>()
			.catch(this.createPromiseErrorHandler(`addAdditionalInformationRequest(${command.portalInstanceId})`));
	}

	private createPromiseErrorHandler(callingOperation = 'operation') {
		return (error: any) => {
			logger.error(`${callingOperation} failed: ${error.message}`);
			if (error.status === 400 && error.error.errors) {
				let validationErrors = error.error.errors as Array<string>;
				throw validationErrors;
			}

			throw error;
		};
	}
}
