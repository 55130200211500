import { Logger } from './logger';
const logger = new Logger('AppComponent');

import { Component } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { AuthService } from './auth/auth.service';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html'
})
export class AppComponent {

	title = 'app';

	constructor(private authService: AuthService, private router: Router) {
		const subscription = this.router.events.subscribe(e => {
			if (e instanceof NavigationEnd) {
				logger.trace('AppComponent NavigationEnd. Removing splash screen.');
				document.getElementById('main-splash').style.display = 'none';
				document.getElementById('main-application').style.display = 'block';
				subscription.unsubscribe();
			}
		});
	}

	ngOnInit() {
		if (environment.useAuthentication)
		{
			// If not authenticated and url contains '/portal', we save this in localStorage and
			// use it by a return.

			// We're back from authentication. We set a redirect with a timer.
			// Also, the return url must contains'code' as query and an another redirect isn't started.
			if (localStorage.getItem('url') && 
				window.location.search.substring(1).indexOf('code=') >= 0 &&
				!localStorage.getItem('url_running')) {
				setTimeout(() => {
					localStorage.setItem('url_running', '1');
					var url = localStorage.getItem('url');
					document.location.href = url;
				}, 1000);

				return;
			}

			// User isn't authenticated with /portal. We save the url in localstorage.
			if (!this.authService.hasValidToken() && 
				document.location.pathname.indexOf('/portal') === 0 && 
				!localStorage.getItem('url')) {
				localStorage.setItem('url', document.location.href);
			}

			// Clean up redirect process after the page is loaded
			if (localStorage.getItem('url_running')) {
				setTimeout(() => {
					localStorage.removeItem('url');
					localStorage.removeItem('url_running');
				}, 10000);
			}

			this.authService.runInitialLoginSequence();
		}
	}
}