<div class="page page-fullpage">
	<img src="/assets/images/logo-octopus-splash.svg" class="octopus-logo" alt="Het logo van Octopus" title="Octopus" />
	<div class="fullpage-content-container">
		<h1>Verlopen</h1>
		<p>
			Het dossier betreffende deze aanlevering is reeds gesloten en kan daarom niet meer geraadpleegd worden.
		</p>
		<p>
			Mocht u vragen hierover hebben, dan kunt u contact opnemen via het telefoonnummer {{ phoneNumber }}
		</p>
	</div>
</div>
