import { Logger } from '../../logger';
const logger = new Logger('ShellMenuComponent');

import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

import { PortalInstance, PortalItemType, PortalItemState, PortalItemSummaryDto, PortalInstanceState } from '../../shared/models';

import { PortalService } from '../portal.service';
import { DialogService } from '../dialog.service';
import { ShellService } from '../shell.service';

@Component({
	selector: 'app-shell-menu',
	templateUrl: './shell-menu.component.html'
})
export class ShellMenuComponent {
	public portalItemTypes = PortalItemType;
	public portalItemStates = PortalItemState;

	@Input() portalInstance: PortalInstance;
	@Input() menuItems: PortalItemSummaryDto[];

	constructor(private dialogService: DialogService, private portalService: PortalService, private shellService: ShellService, private router: Router) { }

	get canAddAdditionalFiles(): boolean {
		let portalInstanceActive = this.portalInstance && this.portalInstance.state === PortalInstanceState.active;
		let hasInformationRequests = this.menuItems && this.menuItems.some(portalItem => portalItem.type === PortalItemType.informationRequest);

		return portalInstanceActive && hasInformationRequests;
	}

	addAdditionalFiles() {
		if (!this.canAddAdditionalFiles) {
			logger.error(`AddAdditionalFiles was called but 'canAddAdditionalFiles' returned false`);
			return;
		}

		let portalId = this.portalInstance.id;

		if (this.hasOpenInformationRequests()) {
			const okButtonText = 'Doorgaan';
			const cancelButtonText = 'Annuleren';
			const contentText = 'U heeft nog een openstaande aanvraag. Weet u zeker dat de beoogde nazending niet bij deze openstaande aanvraag hoort?';

			this.dialogService.createOkCancelDialog(okButtonText, cancelButtonText, contentText)
				.then(() => {
					this.addAdditionalInformationRequest(portalId);
				})
				.catch(() => {
					logger.trace(`AddAdditionalFiles was called but the confirmation dialog was cancelled by user`);
				});
		} else {
			this.addAdditionalInformationRequest(portalId);
		}
	}

	private addAdditionalInformationRequest(portalId: any) {
		let command = { portalInstanceId: portalId };
		this.portalService.addAdditionalInformationRequest(command).then((newInformationRequestId) => {
			// To complex to get the headers and menus right. Just reloading the page.
			this.router.navigate(['portal', command.portalInstanceId, 'informationrequest', newInformationRequestId]).then(_ => {
				location.reload();
			});
		});
	}

	private hasOpenInformationRequests(): boolean {
		return this.menuItems.some(portalItem => portalItem.type === PortalItemType.informationRequest && portalItem.state === PortalItemState.active);
	}
}
