import { Logger } from '../logger';
const logger = new Logger('PortalInstanceResolver');

import { Injectable } from '@angular/core';

import { Observable ,  EMPTY} from 'rxjs';
import { Resolve, ActivatedRouteSnapshot, Router } from '@angular/router';
import { catchError } from 'rxjs/operators';

import { PortalInstance } from '../shared/models';
import { PortalService } from './portal.service';

@Injectable()
export class PortalInstanceResolver implements Resolve<PortalInstance> {
	constructor(private portalService: PortalService, private router: Router) { }

	resolve(route: ActivatedRouteSnapshot): Observable<PortalInstance> {
		const portalId = route.paramMap.get('portalId');
		logger.trace(`Resolve for portal instance: ${portalId}`);

		if (!portalId) {
			return;
		}

		return this.portalService.getPortalInstance(portalId).pipe(
			catchError(err => {
				if (err.status === 404) {
					this.router.navigate(['notfound'], { skipLocationChange: true });
					return EMPTY;
				}

				if (err.status === 410) {
					this.router.navigate(['expired'], { skipLocationChange: true });
					return EMPTY;
				}

				throw err;
			}));
	}
}
