import { Component, OnInit } from '@angular/core';

@Component({
	templateUrl: './not-found.component.html'
})
export class NotFoundComponent implements OnInit {

	constructor() { }

	ngOnInit() {
	}

}
