<div class="menu-main">
	<ul class="nav" *ngIf="portalInstance && menuItems">
		<li *ngFor="let portalItem of menuItems">
			<a [routerLink]="['/portal', portalInstance.id, portalItem.type === portalItemTypes.informationRequest ? 'informationrequest' : 'publication', portalItem.id]"
			 routerLinkActive="active" class="portal-item-state-{{ portalItem.state}}">
				<i [class.ion-folder]="portalItem.type === portalItemTypes.informationRequest" [class.ion-document]="portalItem.type === portalItemTypes.publication"
				 class="fa fa-3x" aria-hidden="true"></i>
				<div>
					<strong>{{ portalItem.title }}</strong>
					<span class="date">{{ portalItem.published | date:'longDate' }}</span>
					<ng-container *ngIf="portalItem.type === portalItemTypes.informationRequest">
						<i class="fa status-icon ion-upload" aria-hidden="true" [hidden]="portalItem.state !== portalItemStates.active"></i>
						<i class="fa status-icon ion-android-cloud-done" aria-hidden="true" [hidden]="portalItem.state !== portalItemStates.submitted && portalItem.state !== portalItemStates.accepted"></i>
						<i class="fa status-icon ion-eye" aria-hidden="true" [hidden]="portalItem.state !== portalItemStates.closed"></i>
					</ng-container>
					<ng-container *ngIf="portalItem.type === portalItemTypes.publication">
						<i class="fa status-icon ion-eye" aria-hidden="true"></i>
					</ng-container>
				</div>
			</a>
		</li>
	</ul>
	<div *ngIf="canAddAdditionalFiles">
		<p style="margin-left: 65px; margin-top: 15px; line-height: 20px;">
			Indien u buiten de gevraagde aanlevering bestanden wilt nazenden klik dan
			<a href="javascript:void(0)" title="Extra aanlevering" (click)="addAdditionalFiles()">hier</a>.
		</p>
	</div>
</div>
