import { Logger } from '../../logger';
const logger = new Logger('EditorComponent');

import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';

const defaultEditorOptions = {
	readOnly: false,
	style: { height: '250px' },
	placeholder: 'Voer uw tekst in ...',
	required: true,
	modules: {
		// see bottom of this .ts file for more options
		toolbar: [
			[{ 'header': [1, 2, 3, 4, 5, 6, false] }],
			['bold', 'italic', 'underline'],
			['link'],
			[{ 'list': 'ordered' }, { 'list': 'bullet' }],
			[{ 'color': [] }],
			['blockquote'],
		]
	}
};

export interface EditorOptions {
	height?: string;
	placeholder?: string;
	required?: boolean;
	readOnly?: boolean;
}

@Component({
	selector: 'app-editor',
	templateUrl: './editor.component.html'
})
export class EditorComponent implements OnInit {
	quillOptions: any;
	@Input() options: EditorOptions;

	internalValue: string;
	@Output() valueChange = new EventEmitter();
	@Input()
	get value() { return this.internalValue; }
	set value(value) {
		this.internalValue = value;
		this.valueChange.emit(this.internalValue);
	}

	ngOnInit() {
		this.quillOptions = defaultEditorOptions;
		let options = this.options;
		if (options) {
			if (options.height != null) {
				this.quillOptions.style.height = options.height;
			}
			if (options.placeholder != null) {
				this.quillOptions.placeholder = options.placeholder;
			}
			if (options.readOnly != null) {
				this.quillOptions.readOnly = options.readOnly;
			}
			if (options.required != null) {
				this.quillOptions.required = options.required;
			}
		}
	}
}

/* Some more options */
// ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
// ['blockquote', 'code-block'],
// [{ 'header': 1 }, { 'header': 2 }],               // custom button values
// [{ 'list': 'ordered'}, { 'list': 'bullet' }],
// [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
/* BL: indents are classes set by quill. Doesn't sound like this will work in a .docx */
// [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
// [{ 'direction': 'rtl' }],                         // text direction
// [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
// [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
// [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
// [{ 'font': [] }],
// [{ 'align': [] }],
// ['clean'],                                         // remove formatting button
// ['link', 'image', 'video']                         // link and image, video
