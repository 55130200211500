import { Logger } from '../logger';
const logger = new Logger('ShellService');

import { Injectable, Output, EventEmitter } from '@angular/core';
import { PortalInstance, PortalItemSummaryDto, IPortalItem } from '../shared/models';

@Injectable()
export class ShellService {
	portalInstance: PortalInstance;
	menuItems: PortalItemSummaryDto[];

	@Output() portalInstanceChange: EventEmitter<PortalInstance> = new EventEmitter();
	@Output() menuItemsChange: EventEmitter<PortalItemSummaryDto[]> = new EventEmitter();

	setPortalInstance(portalInstance: PortalInstance) {
		if (this.portalInstance && this.portalInstance.id === portalInstance.id) { return; }

		logger.trace(`Setting portal instance of Shell service: ${portalInstance.id}`);
		this.setPortalInstanceInternal(portalInstance);
		this.setMenuItemsInternal(portalInstance.items.reverse());
	}

	updatePortalItem(portalItem: IPortalItem) {
		let item = this.menuItems.find(menuItem => menuItem.id === portalItem.id);

		item.state = portalItem.state;
		item.title = portalItem.title;

		this.delayedEmit(this.menuItemsChange, this.menuItems);
	}

	reset() {
		this.setPortalInstanceInternal(null);
		this.setMenuItemsInternal([]);
	}

	private setPortalInstanceInternal(portalInstance: PortalInstance) {
		this.portalInstance = portalInstance;
		this.delayedEmit(this.portalInstanceChange, this.portalInstance);
	}

	private setMenuItemsInternal(menuItems: PortalItemSummaryDto[]) {
		this.menuItems = menuItems;
		this.delayedEmit(this.menuItemsChange, this.menuItems);
	}

	// Set can be called during Angular lifecycle, which limits change detections. Timeout pushes it to next Javascript cycle
	private delayedEmit<T>(emitter: EventEmitter<T>, value: T) {
		setTimeout(() => {
			emitter.emit(value);
		}, 0);
	}
}
