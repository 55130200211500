<div class="block block-scrollable" *ngIf="portalItem">
	<div class="block-header block-header-decorated">
		<h2>Opmerkingen</h2>
		<span class="contact" *ngIf="portalItem.contactPhoneNumber">
			<span class="hidden-md">Vragen?</span>
			<span class="phonenumber">
				<i class="fa ion-ios-telephone"></i>{{ portalItem.contactPhoneNumber }}</span>
		</span>
	</div>
	<div class="block-content">
		{{ portalItem.remarks }}
	</div>
</div>
