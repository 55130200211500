import { Component, Input, OnChanges } from '@angular/core';

import { CaseInformation } from '../../shared/models';
import { CaseService } from '../case.service';

@Component({
	selector: 'app-portal-case-information',
	templateUrl: './case-information.component.html'
})
export class CaseInformationComponent implements OnChanges {
	@Input() caseId: string;
	case: CaseInformation;

	constructor(private caseService: CaseService) { }

	ngOnChanges() {
		this.getCaseInformation();
	}

	getCaseInformation() {
		if (this.caseId) {
			this.caseService.getCaseInformation(this.caseId).subscribe(caseInfo => this.case = caseInfo);
		} else {
			this.case = null;
		}
	}
}
