import { Logger } from '../logger';
const logger = new Logger('ApiInformationService');

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable ,  of } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { environment } from '../../environments/environment';

const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

@Injectable()
export class ApiInformationService {
	private apiInformationUrl = `${environment.apiBaseUrl}/api`;

	constructor(private httpClient: HttpClient) { }

	getVersion(): Observable<string> {
		const apiVersionUri = `${this.apiInformationUrl}/version`;
		logger.trace(`Requesting API version from '${apiVersionUri}'`);
		return this.httpClient.get<string>(apiVersionUri, httpOptions).pipe(
			catchError(this.createErrorHandler('getVersion()', '?.?.?')));
	}

	private createErrorHandler<T>(callingOperation = 'operation', fallbackResult?: T) {
		return (error: any): Observable<T> => {
			logger.error(`${callingOperation} failed: ${error.message}`);
			return of(fallbackResult as T);
		};
	}
}
