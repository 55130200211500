<div class="page page-fullpage">
	<img src="/assets/images/logo-octopus-splash.svg" class="octopus-logo" alt="Het logo van Octopus" title="Octopus" />
	<div class="fullpage-content-container">
		<h1>Aanleverportaal
			<strong class="header-brand">Octopus</strong>
		</h1>
		<p>
			Voor meer informatie over het octopus aanleverportaal, ga naar de website van TrueLime:
			<br>
			<a href="https://www.truelime.nl/nl/contact" title="De contactgegevens pagina van TrueLime">www.truelime.nl</a>
		</p>
	</div>
</div>
