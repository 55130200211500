import { Logger } from '../../logger';
const logger = new Logger('InformationRequestFilesComponent');

import { Component, ViewChild, OnChanges, ElementRef, Input, Output, EventEmitter } from '@angular/core';
import { PortalInstance, InformationRequestDto, PortalItemState } from '../../shared/models';
import { PortalService } from '../portal.service';
import { map } from 'rxjs-compat/operator/map';
import { mapTo } from 'rxjs-compat/operator/mapTo';
import { Subscription } from 'rxjs';
import { unsupported } from '@angular/compiler/src/render3/view/util';

enum FileVmStatus {
	Converting = 1,
	Uploading = 2,
	Added = 3,
	Removing = 4,
	Removed = 5
}

class FileViewModel {
	fileId: any;
	filename: string;
	size: number;
	filedata: File;
	submitted: Date;
	parent: InformationRequestDto;

	statusChange: EventEmitter<void> = new EventEmitter();
	private _status: FileVmStatus;
	get status(): FileVmStatus {
		return this._status;
	}
	set status(value: FileVmStatus) {
		this._status = value;
		this.statusChange.emit();
	}

	get canRemove(): boolean {
		return this.parent.state === PortalItemState.active && this.status === FileVmStatus.Added;
	}

	get adding(): boolean {
		return this.status < FileVmStatus.Added;
	}

	get isAdded(): boolean {
		return this.status === FileVmStatus.Added;
	}

	get working(): boolean {
		return this.status !== FileVmStatus.Added;
	}

	get busy(): boolean {
		return this.status === FileVmStatus.Converting || this.status === FileVmStatus.Uploading || this.status === FileVmStatus.Removing;
	}
}

@Component({
	selector: 'app-information-request-files',
	templateUrl: './information-request-files.component.html'
})
export class InformationRequestFilesComponent implements OnChanges {
	public portalItemStates = PortalItemState;

	@ViewChild('fileInput', { static: false }) fileInputElement: ElementRef;

	@Input() portalInstance: PortalInstance;
	@Input() informationRequest: InformationRequestDto;

	@Output() busy: EventEmitter<boolean> = new EventEmitter<boolean>();
	@Output() hasFiles: EventEmitter<boolean> = new EventEmitter<boolean>();

	FileStatus = FileVmStatus;

	files: FileViewModel[] = [];
	subscriptions : Subscription[] = [];
	allowedExtensions: string[];

	get showFileInputs(): boolean {
		return this.files.length > 0;
	}

	constructor(private portalService: PortalService) { }

	ngOnInit() {
		var listAllowedExtensions = this.portalService.getAllowedFileExtensions().subscribe(extensions => {
			this.allowedExtensions = extensions;
		});
		this.subscriptions.push(listAllowedExtensions);
	}

	ngOnDestroy() {
    	this.subscriptions.forEach(x => {
			if(!x.closed) {
		  	x.unsubscribe();
			}
	  	});
	}

	ngOnChanges() {
		this.reset();
		this.busy.emit(true);
		this.files = this.informationRequest.files.map(fileSummary => {
			let model = new FileViewModel();

			model.fileId = fileSummary.fileId;
			model.filename = fileSummary.filename;
			model.size = fileSummary.size;
			model.status = FileVmStatus.Added;
			model.submitted = fileSummary.submitted;
			model.parent = this.informationRequest;

			model.statusChange.subscribe(this.onFileStatusChange.bind(this));

			return model;
		});

		this.filesChanged();
	}

	onFileStatusChange() {
		let busy = false;
		if (this.files) {
			for (let file of this.files) {
				if (file.busy) {
					busy = true;
					break;
				}
			}
		}

		this.busy.emit(busy);
	}

	reset() {
		this.files = [];
		this.filesChanged();
	}

	chooseFile(event: Event): void {
		logger.debug('chooseFile');
		this.fileInputElement.nativeElement.click();
	}

	onFileInputChange(event: any): void {
		logger.debug('onFileInputChange');

		var unsupported = [];
		for (let file of event.target.files) {
			var extension = '.' + file.name.split('.').pop();
			if (this.allowedExtensions.indexOf(extension)>-1) {
				this.addFile(file);
			}
			else {
				unsupported.push(file.name);
			}
		}
		this.fileInputElement.nativeElement.value = '';

		if (unsupported.length > 0) {
			alert('Enkele bestanden hebben een extensie die niet wordt ondersteund.')
		}
	}

	onFilesDropped(files: File[]): void {
		logger.trace(`Drop target recieved files!`);

		var unsupported = [];
		for (let file of files) {
			var extension = '.' + file.name.split('.').pop();
			if (this.allowedExtensions.indexOf(extension)>-1) {
				this.addFile(file);
			}
			else {
				unsupported.push(file.name);
			}
		}
		if (unsupported.length > 0) {
			alert('Enkele bestanden hebben een extensie die niet wordt ondersteund.')
		}
	}

	addFile(file: File) {
		logger.info(`Adding file: name:${file.name} - type:${file.type} - size:${file.size}`);
		let model = new FileViewModel();
		model.filename = file.name;
		model.size = file.size;
		model.status = FileVmStatus.Converting;
		model.filedata = file;
		model.parent = this.informationRequest;

		model.statusChange.subscribe(this.onFileStatusChange.bind(this));

		this.files.push(model);
		this.filesChanged();
		
		model.status = FileVmStatus.Uploading;
		this.addFileToInformationRequest(file).then(fileId => {
			model.fileId = fileId;
			model.status = FileVmStatus.Added;
			model.submitted = new Date();
			model.filedata = null;
		}).catch(() => {
			// TODO : Notification
			this.removeFileFromLocalList(model);
		});
	}

	addFileToInformationRequest(file: File) {
		let command = {
			portalInstanceId: this.portalInstance.id,
			informationRequestId: this.informationRequest.id
		};

		return this.portalService.addFileToInformationRequest(command, file);
	}

	// downloadFile(model: FileViewModel): void {
	// 	this.portalService.downloadInformationRequestFile(this.portalInstance.id, this.informationRequest.id, model.fileId);
	// }

	removeFile(model: FileViewModel): void {
		logger.info(`Removing file: ${model.filename} (${model.fileId})`);
		model.status = FileVmStatus.Removing;
		const itemIndex = this.files.indexOf(model);
		if (itemIndex > -1) {
			this.deleteFileFromInformationRequest(model.fileId).then(() => {
				this.removeFileFromLocalList(model);
			}).catch(() => {
				// TODO : Notification
				model.status = FileVmStatus.Added;
			});
		}
	}

	deleteFileFromInformationRequest(fileId: any) {
		let command = {
			portalInstanceId: this.portalInstance.id,
			informationRequestId: this.informationRequest.id,
			fileId: fileId
		};

		return this.portalService.deleteFileFromInformationRequest(command);
	}

	removeFileFromLocalList(model: FileViewModel) {
		model.status = FileVmStatus.Removed;
		logger.info(`File removed. ${model.filename} (${model.fileId}). Removing from list`);
		const currentIndex = this.files.indexOf(model);
		this.files.splice(currentIndex, 1);
		this.filesChanged();
	}

	filesChanged() {
		this.hasFiles.emit(this.files.length > 0);
		this.onFileStatusChange();
	}
}
