import { Logger } from '../../logger';
const logger = new Logger('EditorComponent');

import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';

export interface IPersistantTextOptions {
	saveFunc: (text: string) => Promise<void>;
	text: string;
}

@Component({
	selector: 'app-persistant-textarea',
	templateUrl: './persistant-textarea.component.html'
})
export class PersistantTextareaComponent implements OnInit {
	@Input() rows = 5;
	@Input() placeholder = 'Voer uw tekst in ...';
	@Input() required = false;
	@Input() saveTimeout = 1000;

	stateFeedback: string;

	private saveFunc: (text: string) => Promise<void>;
	private textChange = new EventEmitter();
	private textChangedSubscription: any;
	private saveTextTimeout: any;
	private helperValue: string;

	get text(): string { return this.helperValue; }
	set text(value: string) {
		if (this.helperValue === value) { return; }
		this.helperValue = value;
		this.textChange.emit(this.helperValue);
	}

	@Input()
	set options(value: IPersistantTextOptions) {
		this.reset();
		this.text = value.text;
		this.saveFunc = value.saveFunc;
		this.textChangedSubscription = this.textChange.subscribe(this.onValueChange.bind(this));
	}

	working: boolean;
	@Output() busy = new EventEmitter<boolean>();

	ngOnInit() {
		this.busy.subscribe(value => this.working = value);
	}

	private onValueChange(newValue: string) {
		this.busy.emit(true);
		this.stateFeedback = 'tekst gewijzigd';

		if (this.saveTextTimeout) { clearTimeout(this.saveTextTimeout); }
		this.saveTextTimeout = setTimeout(() => {
			this.stateFeedback = 'opslaan ...';
			this.saveFunc(newValue).then(() => {
				this.stateFeedback = 'opgeslagen';
			}).catch(error => {
				if (error instanceof Array) {
					this.stateFeedback = `Er is een fout opgetreden: "${error.join('", "')}"`;
				} else {
					this.stateFeedback = 'Er is een fout opgetreden. Pas de tekst aan om het opnieuw te proberen.';
				}
			}).then(() => { // then after catch = always
				this.busy.emit(false);
			});
		}, this.saveTimeout);
	}

	private reset(): void {
		if (this.saveTextTimeout) { clearTimeout(this.saveTextTimeout); }
		if (this.textChangedSubscription) {
			this.textChangedSubscription.unsubscribe();
			this.textChangedSubscription = null;
		}

		this.stateFeedback = '';

		this.busy.emit(false);
	}
}
