import { Logger } from '../logger';
const logger = new Logger('PortalShellComponent');

import { Component, OnInit, OnDestroy } from '@angular/core';
import { PortalInstance, PortalItemSummaryDto } from '../shared/models';
import { ShellService } from './shell.service';
import { DialogService } from './dialog.service';
import { OkCancelModalOptions } from '../shared/ok-cancel-modal/ok-cancel-modal.component';
import { AuthService } from '../auth/auth.service';
import { environment } from '../../environments/environment';


@Component({
	selector: 'app-portal-root',
	templateUrl: './shell.component.html'
})
export class PortalShellComponent implements OnInit, OnDestroy {
	portalInstance: PortalInstance;
	menuItems: PortalItemSummaryDto[];
	okCancelModals: OkCancelModalOptions[] = [];
	fullName = '';

	constructor(private authService: AuthService, private shellService: ShellService, private dialogService: DialogService) { }

	ngOnInit() {
		if (environment.useAuthentication)
		{
			this.authService.isAuthenticated$.pipe().subscribe( () => {
				if (this.authService.identityClaims !== null && 
					this.authService.identityClaims["family_name"]){
				this.fullName = this.authService.identityClaims["family_name"];
					}
			});
		}
		this.shellService.portalInstanceChange.subscribe(portalInstance => {
			this.portalInstance = portalInstance;
		});
		this.shellService.menuItemsChange.subscribe(menuItems => {
			this.menuItems = menuItems;
		});

		this.dialogService.okCancelDialogAdded.subscribe((modalData: OkCancelModalOptions) => {
			this.okCancelModals.push(modalData);
		});
	}

	ngOnDestroy() {
		this.shellService.reset();
	}
}
