<div>
	<span>
		Angular:
		<span>{{ angularVersion }}</span>
	</span>
	|
	<span>
		FrontEnd:
		<span>{{ octopusPortaalFrontEndVersion }}</span>
	</span>
	|
	<span>
		Configuration:
		<span>{{ octopusPortaalFrontEndConfiguration }}</span>
	</span>
	|
	<span>
		Api:
		<span>{{ octopusPortaalApiVersion }}</span>
	</span>
</div>
