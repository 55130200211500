import { Logger } from '../../logger';
const logger = new Logger('PortalInstanceComponent');

import { Component, OnInit } from '@angular/core';
import { ShellService } from '../shell.service';
import { ActivatedRoute } from '@angular/router';

import { PortalInstance } from '../../shared/models';

@Component({
	selector: 'app-portal-instance',
	templateUrl: './portal-instance.component.html'
})
export class PortalInstanceComponent implements OnInit {
	portalInstance: PortalInstance;
	constructor(private shellService: ShellService, private route: ActivatedRoute) { }

	ngOnInit() {
		this.route.data.subscribe((data: { portalInstance: PortalInstance }) => {
			logger.trace(`Route data changed. PortalInstance: ${data.portalInstance}`);

			this.portalInstance = data.portalInstance;
			this.shellService.setPortalInstance(data.portalInstance);
		});
	}
}
