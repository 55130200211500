<header>
	<app-portal-item-header [portalItem]="publication"></app-portal-item-header>
</header>
<section class="main-content" *ngIf="publication">
	<div class="row">
		<div class="col-md-6">
			<app-portal-item-remarks [portalItem]="publication"></app-portal-item-remarks>
		</div>
		<div class="col-md-6">
			<app-portal-case-information [caseId]="caseId"></app-portal-case-information>
		</div>
	</div>
	<div class="row">
		<div class="col-md-12">
			<div class="block">
				<div class="row">
					<div class="col-md-12">
						<div class="block-header">
							<h2>{{ publication.title }}</h2>
						</div>
						<div class="block-content">
							<ul class="fa-ul portal-item-files">
								<li *ngFor="let file of files">
									<div class="col-md-4">
									<div class="files-names">
										<i class="fa fa-li ion-document" aria-hidden="true"></i>
										<span class="fileinfo-filename">{{ file.filename }}</span>
									</div>
								</div>
									<div class="files-names">
										<span class="fileinfo-description">{{ file.description }}</span>
									</div>
									<div class="files-meta" style="float:right">
										<span class="fileinfo-size">{{ file.size | humanReadableFileSize }}</span>
										<button class="btn btn-link" (click)="downloadFile(file)">
											<i class="fa ion-android-download" aria-hidden="true"></i>
										</button>
									</div>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>
