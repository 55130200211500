<div class="structure-sidebar">
	<section class="sidebar">
		<div class="sidebar-logo">
			<img src="/assets/images/logo-octopus.svg" class="octopus-logo" alt="Het logo van Octopus" title="Octopus" />
		</div>
		<div class="menu-main-title">{{fullName}}</div>
		<div class="menu-main-container">
			<app-shell-menu [portalInstance]="portalInstance" [menuItems]="menuItems"></app-shell-menu>
		</div>
		<div class="sidebar-footer">
			<img src="/assets/images/logo-Truelime.svg" class="truelime-logo" alt="Het logo van TrueLime" title="TrueLime - Software om blij van te worden">
		</div>
	</section>
</div>
<div class="structure-main">
	<div class="container-fluid">
		<div>
			<router-outlet></router-outlet>
		</div>
	</div>
</div>

<!-- Modals -->
<!-- OkCancelDialogs -->
<div *ngIf="okCancelModals">
	<div *ngFor="let okCancelModalOptions of okCancelModals">
		<app-ok-cancel-modal [options]="okCancelModalOptions"></app-ok-cancel-modal>
	</div>
</div>

<!-- <div class="modal fade" tabindex="-1" role="dialog" modal>
	<div class="modal-dialog" role="document">
		<div class="modal-content">
			<div class="modal-header">
				<button type="button" class="close" data-dismiss="modal" aria-label="Close">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body">
				<p>
					U heeft nog een openstaande aanvraag. Weet u zeker dat de beoogde nazending niet bij deze openstaande aanvraag hoort?
				</p>
				<button type="button" class="btn btn-primary">Doorgaan</button>
				<button type="button" class="btn btn-default" data-dismiss="modal">Annuleren</button>
			</div>
		</div>
	</div>
</div> -->
