import { Component } from '@angular/core';
import { environment } from '../../../environments/environment';

@Component({
	templateUrl: './expired.component.html'
})
export class ExpiredComponent {
	phoneNumber: string = environment.contactPhoneNumber;

	constructor() { }
}
