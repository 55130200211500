import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { HomeComponent } from './pages/home/home.component';
import { ExpiredComponent } from './pages/expired/expired.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { AuthAutoLoginGuard } from './auth/auth-auto-login.guard';

const appRoutes: Routes = [
	{ path: '', component: HomeComponent, canActivate: [AuthAutoLoginGuard] },
	{ path: 'expired', component: ExpiredComponent, canActivate: [AuthAutoLoginGuard] },
	{ path: 'notfound', component: NotFoundComponent, canActivate: [AuthAutoLoginGuard] },
	{ path: '**', redirectTo: 'notfound' }
];

@NgModule({
	imports: [
		RouterModule.forRoot(appRoutes, { relativeLinkResolution: 'legacy' })
	],
	exports: [
		RouterModule
	],
	providers: []
})
export class AppRoutingModule { }
