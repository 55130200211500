import { Logger } from '../logger';
const logger = new Logger('InformationRequestResolver');

import { Injectable } from '@angular/core';

import { Observable ,  EMPTY} from 'rxjs';
import { Resolve, RouterStateSnapshot, ActivatedRouteSnapshot, Router } from '@angular/router';
import { catchError } from 'rxjs/operators';

import { InformationRequestDto } from '../shared/models';
import { PortalService } from './portal.service';

@Injectable()
export class InformationRequestResolver implements Resolve<InformationRequestDto> {
	constructor(private portalService: PortalService, private router: Router) { }

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<InformationRequestDto> {
		const portalId = route.paramMap.get('portalId');
		const informationRequestId = route.paramMap.get('informationRequestId');
		logger.trace(`Resolve for information request: ${informationRequestId} (instance: ${portalId})`);

		if (!portalId || !informationRequestId) {
			return;
		}

		return this.portalService.getInformationRequest(portalId, informationRequestId).pipe(
			catchError(err => {
				if (err.status === 404) {
					this.router.navigate(['notfound'], { skipLocationChange: true });
					return EMPTY;
				}

				if (err.status === 410) {
					this.router.navigate(['expired'], { skipLocationChange: true });
					return EMPTY;
				}

				throw err;
			}));
	}
}
