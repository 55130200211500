import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'humanReadableFileSize'
})
export class HumanReadableFileSizePipe implements PipeTransform {
	private units = ['bytes', 'KB', 'MB', 'GB'];

	transform(bytes?: number, args?: any): string {
		if (bytes == null) {
			return `0${this.units[1]}`;
		}

		let size = bytes;
		let unit = 0;

		while (size >= 1024 && unit < (this.units.length - 1)) {
			size /= 1024;
			unit++;
		}

		return `${Number.isInteger(size) ? size : size.toFixed(1)}${this.units[unit]}`;
	}
}
