<div class="block block-scrollable">
	<div class="block-header">
		<h2 *ngIf="case">{{ case.title }}</h2>
	</div>
	<div class="block-content">
		<table class="summary-table" *ngIf="case">
			<tr *ngFor="let property of case.properties">
				<th>{{ property.name }}:</th>
				<td>{{ property.value }}</td>
			</tr>
		</table>
	</div>
</div>
