import { Injectable } from '@angular/core';

// declaring require to trick TS. require WILL exist at runtime
declare var require: any;
const packageInformation = require('../../../package.json');

@Injectable()
export class PackageInformationService {
	getVersion(): string {
		return packageInformation.version;
	}
}
