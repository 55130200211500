<div class="row" *ngIf="portalItem">
	<div class="col-md-7">
		<!-- Back button would be nice. Maybe here? -->
		<a [routerLink]="['/portal', portalItem.portalInstanceId]" class="portal-item-back">
			<i class="fa fa-2x ion-android-arrow-back" aria-hidden="true"></i>
		</a>
		<h1>{{ portalItem.title }}</h1>
	</div>
	<div class="col-md-5">
		<div class="duedate">
			<ng-container *ngIf="deadline">
				<i class="fa ion-alert-circled fa-warning" aria-hidden="true" [hidden]="portalItem.state !== portalItemStates.active"></i>
				<i class="fa ion-android-time" aria-hidden="true" [hidden]="portalItem.state !== portalItemStates.submitted"></i>
				<i class="fa ion-android-cloud-done fa-success" aria-hidden="true" [hidden]="portalItem.state !== portalItemStates.accepted"></i>
				<span>uiterlijke aanleverdatum:</span>
				<span class="date">{{ deadline | date:'d MMMM yyyy' }}</span>
			</ng-container>
			<ng-container *ngIf="!deadline">
				<span>gepubliceerd op:</span>
				<span class="date">{{ portalItem.publish | date:'d MMMM yyyy' }}</span>
			</ng-container>
		</div>
	</div>
</div>
