import { Logger } from '../../logger';
const logger = new Logger('OkCancelModalComponent');

declare var $: any;

import { Component, ElementRef, Input, OnInit } from '@angular/core';

export interface OkCancelModalOptions {
	okButtonText: string;
	cancelButtonText: string;
	content: string;
	htmlContent: boolean;
	resolve: (value?: void | PromiseLike<void>) => void;
	reject: (reason?: any) => void;
}

@Component({
	selector: 'app-ok-cancel-modal',
	templateUrl: './ok-cancel-modal.component.html'
})
export class OkCancelModalComponent implements OnInit {
	@Input() options: OkCancelModalOptions;

	private $modal: any;

	constructor(private componentElement: ElementRef) { }

	ngOnInit(): void {
		this.$modal = $(this.componentElement.nativeElement.firstChild);
		this.$modal.modal({ show: true, backdrop: 'static', keyboard: false });
	}

	okClick(): void {
		this.onOk();
	}

	cancelClick(): void {
		this.onCancel();
	}

	closeClick(): void {
		this.onCancel();
	}

	private onOk() {
		this.options.resolve();
		this.closeModal();
	}

	private onCancel() {
		this.options.reject();
		this.closeModal();
	}

	private closeModal() {
		this.$modal.modal('hide');
	}
}
