import { Logger } from '../../logger';
const logger = new Logger('PortalItemRemarksComponent');

import { Component, Input } from '@angular/core';

import { IPortalItem } from '../../shared/models';

@Component({
	selector: 'app-portal-item-remarks',
	templateUrl: './portal-item-remarks.component.html'
})
export class PortalItemRemarksComponent {
	@Input() portalItem: IPortalItem;
}
