export interface CaseInformation {
	title: string;
	properties: CaseProperty[];
}

export interface CaseProperty {
	name: string;
	value: string;
}

export interface PortalInstance {
	id: string;
	caseId: string;
	state: PortalInstanceState;
	items: PortalItemSummaryDto[];
}

export interface PortalItemSummaryDto {
	id: string;
	type: PortalItemType;
	state: PortalItemState;
	title: string;
	published: Date;
	deadline?: Date;
}

export interface FileSummaryDto {
	fileId: any;
	filename: string;
	size: number;
	submitted: Date;
	description: string;
}

export interface IPortalItem {
	id: any;
	portalInstanceId: any;
	caseId: string;
	state: PortalItemState;
	title: string;
	remarks: string;
	contactPhoneNumber: string;
	publish: Date;
}

export interface InformationRequestDto extends IPortalItem {
	allowFiles: boolean;
	allowText: boolean;
	requireFiles: boolean;
	requireText: boolean;
	customText: string;
	deadline?: Date;
	accepted?: Date;
	files: FileSummaryDto[];
}

export interface PublicationDto extends IPortalItem {
	files: FileSummaryDto[];
}

export interface Base64File {
	filename: string;
	dataType: string;
	data: string;
	size: number;
}

export enum PortalInstanceState {
	active = 1,
	closed = 9,
	archived = 99
}

export enum PortalItemState {
	active = 1,
	submitted = 2,
	accepted = 3,
	closed = 9,
}

export enum PortalItemType {
	informationRequest = 1,
	publication = 2,
}
