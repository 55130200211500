import { Logger } from '../logger';
const logger = new Logger('CaseService');

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable ,  of } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { environment } from '../../environments/environment';

import { CaseInformation } from '../shared/models';

const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

@Injectable()
export class CaseService {
	private caseUrl = `${environment.apiBaseUrl}/api/case`;

	constructor(private http: HttpClient) { }

	getCaseInformation(caseId: string): Observable<CaseInformation> {
		const apiCaseInfoUrl = `${this.caseUrl}/${caseId}/information`;
		logger.trace(`Requesting Case information for '${caseId}' from '${apiCaseInfoUrl}'`);
		return this.http.get<CaseInformation>(apiCaseInfoUrl, httpOptions).pipe(
			catchError(this.createErrorHandler(`getCaseInformation(${caseId})`, null)));
	}

	// Do we want to catch it or just throw?
	private createErrorHandler<T>(callingOperation = 'operation', fallbackResult?: T) {
		return (error: any): Observable<T> => {
			logger.error(`${callingOperation} failed: ${error.message}`);
			return of(fallbackResult as T);
		};
	}
}
