<div class="row">
	<div class="col-md-6" [hidden]="!showFileInputs">
		<p *ngIf="informationRequest.state === portalItemStates.active">
			Uploads blijven beschikbaar als u uw browservenster sluit
		</p>
		<ul class="fa-ul portal-item-files">
			<li *ngFor="let file of files" [class.working]="file.working">
				<div class="files-names">
					<i class="fa fa-li ion-document" aria-hidden="true"></i>
					<span class="fileinfo-filename">{{ file.filename }}</span>
				</div>
				<div class="files-meta" style="float:right">
					<span class="fileinfo-size" [hidden]="!file.adding">{{ file.size | humanReadableFileSize }}</span>
					<span class="fileinfo-submitted" [hidden]="!file.isAdded">{{ file.submitted | date:'d MMMM yyyy' }}</span>
					<button class="btn btn-link" [hidden]="!file.working">
						<i class="fa fa-spin ion-load-c" aria-hidden="true"></i>
					</button>
					<!-- <button class="btn btn-link" (click)="downloadFile(file)" *ngIf="file.isAdded">
						<i class="fa ion-android-download" aria-hidden="true"></i>
					</button> -->
					<button class="btn btn-link" (click)="removeFile(file)" [hidden]="!file.canRemove" [disabled]="!file.canRemove">
						<i class="fa ion-ios-close-round" aria-hidden="true"></i>
					</button>
				</div>
			</li>
		</ul>
	</div>
	<div [class.col-md-offset-1]="!showFileInputs" [class.col-md-10]="!showFileInputs" [class.col-md-6]="showFileInputs">
		<ng-container *ngIf="informationRequest.state > portalItemStates.active">
			<div class="drop-area state-feedback">
				<p *ngIf="informationRequest.state === portalItemStates.submitted">
					U kunt hier geen bestanden meer toevoegen aan dit verzoek omdat de bestanden al zijn bevestigd en verstuurd.
				</p>
				<p *ngIf="informationRequest.state === portalItemStates.accepted">
					U kunt hier geen bestanden meer toevoegen aan dit verzoek omdat dit verzoek al is afgerond.
				</p>
				<p *ngIf="informationRequest.state === portalItemStates.closed">
					Het portaal is gesloten. De bestanden zijn tijdelijk nog te downloaden.
				</p>
			</div>
		</ng-container>
		<ng-container *ngIf="informationRequest.state === portalItemStates.active">
			<div class="drop-area" appDragAndDrop (filesDropped)="onFilesDropped($event)">
				<i class="fa fa-5x ion-upload" aria-hidden="true"></i>
				<p>
					Sleep hier of selecteer de bestanden op je computer.
				</p>
				<input #fileInput type="file" (change)="onFileInputChange($event)" multiple style="display: none">
				<button class="btn btn-lg btn-primary" type="button" (click)="chooseFile($event)">Kies bestanden</button>
			</div>
		</ng-container>
	</div>
</div>
