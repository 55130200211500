import { NgModule, LOCALE_ID } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localeNlNl from '@angular/common/locales/nl';

registerLocaleData(localeNlNl);

import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';

import { Router } from '@angular/router';
import { AppRoutingModule } from './app-routing.module';
import { HomeComponent } from './pages/home/home.component';
import { ExpiredComponent } from './pages/expired/expired.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';

import { PortalModule } from './portal/portal.module';

/* Components */
import { AppComponent } from './app.component';
import { AppInformationComponent } from './app-information/app-information.component';

/* Custom services */
import { ApiInformationService } from './app-information/api-information.service';
import { PackageInformationService } from './app-information/package-information.service';
import { AuthCoreModule } from './auth/auth.module';

@NgModule({
	declarations: [
		AppComponent,
		AppInformationComponent,
		HomeComponent,
		ExpiredComponent,
		NotFoundComponent,
	],
	imports: [
		BrowserModule,
		HttpClientModule,
		PortalModule,
		AppRoutingModule,
		AuthCoreModule.forRoot()
	],
	providers: [
		ApiInformationService,
		PackageInformationService,
		{ provide: LOCALE_ID, useValue: 'nl-NL' }
	],
	bootstrap: [AppComponent]
})
export class AppModule {
	constructor(router: Router) {
		console.log('Routes: ', JSON.stringify(router.config, undefined, 2));
	}
}
