import { Logger } from '../../logger';
const logger = new Logger('InformationRequestComponent');

import { Component, OnInit } from '@angular/core';
import { ShellService } from '../shell.service';
import { ActivatedRoute, Router } from '@angular/router';

import { PortalInstance, InformationRequestDto, PortalItemState } from '../../shared/models';
import { PortalService } from '../portal.service';
import { IPersistantTextOptions } from '../../shared/persistant-textarea/persistant-textarea.component';

interface FeedbackMessage {
	success: boolean;
	header: string;
	message: string;
}

@Component({
	templateUrl: './information-request.component.html'
})
export class InformationRequestComponent implements OnInit {
	public portalItemStates = PortalItemState;

	parent: PortalInstance;
	caseId: string;
	informationRequest: InformationRequestDto;
	state: PortalItemState;

	// local to solve problems with changetracking
	persistantTextareaOptions: IPersistantTextOptions;
	customTextBusy: boolean;
	filesBusy: boolean;
	hasFiles: boolean;

	feedbackMessage: FeedbackMessage = null;
	confirming = false;

	constructor(
		private router: Router,
		private shellService: ShellService,
		private portalService: PortalService,
		private route: ActivatedRoute) { }

	ngOnInit() {
		this.route.data.subscribe((data: { portalInstance: PortalInstance, informationRequest: InformationRequestDto }) => {
			logger.trace(`Route data changed. PortalInstance: ${data.portalInstance}, InformationRequest: ${data.informationRequest}`);

			this.setFeedback(null);
			this.confirming = false;

			this.parent = data.portalInstance;
			this.caseId = data.portalInstance.caseId;
			this.informationRequest = data.informationRequest;
			this.state = data.informationRequest.state;
			this.shellService.setPortalInstance(data.portalInstance);

			this.persistantTextareaOptions = {
				saveFunc: this.saveCustomText.bind(this),
				text: this.informationRequest.customText
			};
		});
	}

	get isDirty(): boolean {
		// Dirty basically means it has a value.
		let filesDirty = this.hasFiles;
		let textDirty = (this.informationRequest.customText && true);

		return filesDirty || textDirty;
	}

	get isValid(): boolean {
		let filesValid = !this.informationRequest.requireFiles || this.hasFiles;
		let textValid = !this.informationRequest.requireText || (this.informationRequest.customText && true);

		return filesValid && textValid;
	}

	get confirmEnabled(): boolean {
		return !this.filesBusy && !this.customTextBusy && this.isDirty && this.isValid && !this.confirming;
	}

	get confirmVisible(): boolean {
		return this.informationRequest.state === PortalItemState.active;
	}

	confirm() {
		if (!this.confirmEnabled) {
			throw new Error('Confirm called illegally');
		}

		this.setFeedback(null);
		this.confirming = true;

		let command = {
			portalInstanceId: this.parent.id,
			informationRequestId: this.informationRequest.id
		};

		this.portalService.submitInformationRequest(command).then(() => {
			this.setFeedback({
				success: true,
				header: 'Uw stukken zijn verstuurd',
				message: 'De behandelaar van deze zaak heeft een notificatie ontvangen dat de stukken zijn verstuurd.'
			});
			this.informationRequest.state = PortalItemState.submitted;
			this.shellService.updatePortalItem(this.informationRequest);
		}).catch(() => {
			this.setFeedback({
				success: false,
				header: 'Oops!',
				message: 'Er is iets mis gegaan. Probeer het later opnieuw. Blijft dit probleem zich voordoen, probeer dan de pagina te verversen.'
			});
		}).then(() => { // then after catch = always
			this.confirming = false;
		});
	}

	saveCustomText(text: string): Promise<void> {
		let command = {
			portalInstanceId: this.parent.id,
			informationRequestId: this.informationRequest.id,
			customText: text
		};

		return this.portalService.updateCustomTextOfInformationRequest(command).then(() => {
			this.informationRequest.customText = text;
		});
	}

	private setFeedback(feedback: FeedbackMessage) {
		this.feedbackMessage = feedback;
	}
}
