<header>
	<app-portal-item-header [portalItem]="informationRequest" [deadline]="informationRequest?.deadline"></app-portal-item-header>
</header>
<section class="main-content" *ngIf="informationRequest">
	<div class="row">
		<div class="col-md-6">
			<app-portal-item-remarks [portalItem]="informationRequest"></app-portal-item-remarks>
		</div>
		<div class="col-md-6">
			<app-portal-case-information [caseId]="caseId"></app-portal-case-information>
		</div>
	</div>
	<div class="form">
		<div class="block">
			<div class="block-header">
				<h2>{{ informationRequest.title }}</h2>
			</div>
			<div class="block-content">
				<div class="form-group" *ngIf="informationRequest.allowFiles">
					<app-information-request-files [portalInstance]="parent" [informationRequest]="informationRequest" (busy)="filesBusy = $event"
					 (hasFiles)="hasFiles = $event"></app-information-request-files>
				</div>
				<div class="form-group" *ngIf="informationRequest.allowText">
					<ng-container *ngIf="informationRequest.state === portalItemStates.active">
						<app-persistant-textarea [rows]="5" [options]="persistantTextareaOptions" (busy)="customTextBusy = $event"></app-persistant-textarea>
					</ng-container>
					<ng-container *ngIf="informationRequest.state === portalItemStates.submitted">
						<blockquote>
							{{ informationRequest.customText }}
						</blockquote>
					</ng-container>
				</div>

				<div class="form-group" *ngIf="confirmVisible">
					<button class="btn btn-lg btn-submit" type="submit" [disabled]="!confirmEnabled" (click)="confirm()">Bevestigen en versturen</button>
					<span [hidden]="!confirming"></span>
				</div>

				<div class="form-group" *ngIf="feedbackMessage">
					<div [class.feedback-success]="feedbackMessage.success" [class.feedback-error]="!feedbackMessage.success">
						<h2>{{ feedbackMessage.header }}</h2>
						<p>
							{{ feedbackMessage.message }}
						</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>
