import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { QuillModule } from 'ngx-quill';

import { DragAndDropDirective } from '../drag-and-drop.directive';
import { HumanReadableFileSizePipe } from '../shared/human-readable-file-size.pipe';
import { FileService } from './file.service';

import { PersistantTextareaComponent } from './persistant-textarea/persistant-textarea.component';
import { OkCancelModalComponent } from './ok-cancel-modal/ok-cancel-modal.component';
import { EditorComponent } from '../shared/editor/editor.component';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		QuillModule
	],
	declarations: [
		DragAndDropDirective,
		HumanReadableFileSizePipe,
		PersistantTextareaComponent,
		OkCancelModalComponent,
		EditorComponent
	],
	providers: [FileService],
	exports: [
		DragAndDropDirective,
		HumanReadableFileSizePipe,
		PersistantTextareaComponent,
		OkCancelModalComponent,
		EditorComponent
	]
})
export class SharedModule { }
