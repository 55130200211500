import { Logger } from './logger';
const logger = new Logger('DragAndDropDirective');

import { Directive, HostBinding, HostListener, EventEmitter, Output } from '@angular/core';

@Directive({
	selector: '[appDragAndDrop]'
})
export class DragAndDropDirective {
	@Output() filesDropped: EventEmitter<File[]> = new EventEmitter();

	constructor() { }

	@HostBinding('class.dragover') private dragOver = false;
	@HostListener('dragover', ['$event']) onDragOver(evt: any) {
		evt.preventDefault();
		evt.stopPropagation();
		if (this.getFilesFromEvent(evt).length > 0) {
			this.dragOver = true;
		}
	}
	@HostListener('dragleave', ['$event']) onDragLeave(dragEvent: any) {
		dragEvent.preventDefault();
		dragEvent.stopPropagation();
		this.dragOver = false;
	}
	@HostListener('drop', ['$event']) public onDrop(dragEvent: any) {
		dragEvent.preventDefault();
		dragEvent.stopPropagation();
		this.dragOver = false;

		const files = this.getFilesFromEvent(dragEvent);

		if (files.length < 1) { return; }

		for (let file of files) {
			logger.trace(`File dropped: name:${file.name} - type:${file.type} - size:${file.size}`);
		}

		this.filesDropped.emit(files);
	}

	// browser shananigans...
	private getFilesFromEvent(dragEvent: any): File[] {
		let result = [];

		// chrome uses items on drag but no files.
		const itemList = dragEvent.dataTransfer.items;
		// typing of itemList and fileList is incorrect. Do not use 'foreach' or 'for of'
		for (let i = 0; i < itemList.length; i++) {
			const item = itemList[i];
			if (item.kind !== 'file') {
				continue;
			}

			// getAsFile might return null!
			result.push(item.getAsFile());
		}

		if (result.length > 0) { return result; }

		const fileList = dragEvent.dataTransfer.files;
		// typing of itemList and fileList is incorrect. Do not use 'foreach' or 'for of'
		for (let i = 0; i < fileList.length; i++) {
			const file = fileList[i];
			result.push(file);
		}

		return result;
	}
}
